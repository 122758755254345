const MODE = {
  production: 'prod',
  development: 'dev',
  test: 'test',
} as const;

export const isDevelopment = () =>
  process.env.REACT_APP_HOPECITY_MODE === MODE.development;
export const isProduction = () =>
  process.env.REACT_APP_HOPECITY_MODE === MODE.production;
export const isTesting = () =>
  process.env.REACT_APP_HOPECITY_MODE === MODE.test;
