import Loader from 'app/components/Loader';
import Table from 'app/components/Table';
import TableInner from 'app/components/Table/TableInner';
import useTableState from 'app/components/Table/useTableState';
import { useGetQuery } from 'hooks/reactQueryHelper';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { CellProps, Column } from 'react-table';
import { KTSVG } from 'theme/helpers';
import EditDateModel from './EditDateModal';
import { PageTitle } from 'theme/layout/core';
import EnrollmentHeadPageTab from '../EnrollmentHeadPageTab';

const UpdateDeadlineDate = ({ show, setUpdateDeadlineDate }: any) => {
  const getCalenderYear: any = useGetQuery(
    'getCalenderYear',
    '/enrollment/calendartype/get'
  );

  const [openEditDateModal, setOpenEditDateModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedCalenderType, setSelectedCalenderType] = useState(null);

  useEffect(() => {
    getCalenderYear.mutateAsync({});
  }, []);

  const [tableInitialState, tableState, handleTableStateChange] =
    useTableState<object>({
      pageIndex: 0,
    });

  const columns = React.useMemo<Column<any>[]>(
    () => [
      {
        Header: () => (
          <span className='ps-4 rounded-start justify-content-start'>
            School year
          </span>
        ),
        accessor: 'StudentID',
        Cell: (props: CellProps<any>) => {
          return (
            <div className='d-flex'>
              <a className='text-dark fw-bold d-block ms-3 fs-6 text-hover-primary text-center'>
                {props?.row?.original?.label}
              </a>
            </div>
          );
        },
      },
      {
        Header: () => (
          <span className='pe-4 d-block text-end rounded-end'>Actions</span>
        ),
        accessor: 'actions',
        disableSortBy: true,
        Cell: (props: CellProps<any>) => {
          const result = props?.row?.original;
          return (
            <div className='d-flex justify-content-end flex-shrink-0'>
              <button
                className='btn btn-icon btn-primary btn-sm me-1'
                title='edit'
                onClick={() => {
                  setSelectedId(result?._id);
                  setSelectedCalenderType(result?.label);
                  setOpenEditDateModal(true);
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/art/art005.svg'
                  className='svg-icon-3'
                />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <EditDateModel
        selectedId={selectedId}
        selectedCalenderType={selectedCalenderType}
        show={openEditDateModal}
        setOpenEditDateModal={setOpenEditDateModal}
      />

      <div className='card mb-'>
        <div className='card text-end mt-5 p-6'>
          <div className='pt-5'>
            <EnrollmentHeadPageTab activeTab='Transcript' />
          </div>
          <div className='card-body p-6'>
            <h1>Edit grade submission deadline date</h1>
          </div>
        </div>
        <Loader loading={getCalenderYear.isLoading} />
        <Table
          data={getCalenderYear?.data?.ResultObject || []}
          //   pageIndex={3}
          columns={columns}
          initialState={tableInitialState}
          pageCount={getCalenderYear?.data?.ResultObject?.totalPages ?? -1}
          onTableStateChange={handleTableStateChange}
        >
          <React.Fragment>
            <div className='card-body py-3'>
              <div className='row'>
                <div className='col-12 mb-5'>
                  <TableInner
                    loading={getCalenderYear.isLoading}
                    entityLabel='calender year'
                  />
                </div>
                <div className='col-12 border-top pt-5'></div>
              </div>
            </div>
          </React.Fragment>
        </Table>
      </div>
    </>
  );
};

export default UpdateDeadlineDate;
