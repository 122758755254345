import React from 'react';
import './loader.css';

const LoaderNew: React.FC<{
  loading?: boolean;
  isFull?: boolean;
  className?: string;
}> = (props: any) => {
  const { loading = false, isFull = true, className = '' } = props;

  return (
    <>
      {loading && (
        <div
          className={`overlay d-flex justify-content-center align-items-center ${className} ${
            isFull ? 'position-fixed' : 'position-absolute'
          }`}
        >
          <div className='spinner-border text-primary fs-3' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      )}
    </>
  );
};
export default LoaderNew;
