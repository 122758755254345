import React from 'react';

const Loader: React.FC<{ loading?: boolean }> = ({ loading }) => {
  React.useEffect(() => {
    loading && document.body.classList.add('page-loading');
  }, [loading]);

  return loading ? (
    <div className='page-loader flex-column bg-white bg-opacity-50'>
      <span className='spinner-border text-primary fs-3' role='status'></span>
    </div>
  ) : null;
};

export default Loader;
