function generateBirthYears() {
  let currentYear = new Date().getFullYear();
  const startYear = 1900;
  const birthYears = [];
  currentYear = currentYear - 4;

  for (let year = startYear; year <= currentYear; year++) {
    birthYears.push(year);
  }

  return birthYears.reverse();
}
export default generateBirthYears;
