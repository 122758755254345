import Swal from 'sweetalert2';

const useToaster = () => {
  const toaster = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  const successToaster = (title) => {
    toaster.fire({
      icon: 'success',
      title: title,
    });
  };
  const errorToaster = (title) => {
    toaster.fire({
      icon: 'error',
      title: title,
    });
  };
  const warningToaster = (title) => {
    toaster.fire({
      icon: 'warning',
      title: title,
    });
  };
  const infoToaster = (title) => {
    toaster.fire({
      icon: 'info',
      title: title,
    });
  };
  const questionToaster = (title) => {
    toaster.fire({
      icon: 'question',
      title: title,
    });
  };

  return {
    toaster,
    successToaster,
    errorToaster,
    warningToaster,
    infoToaster,
    questionToaster,
  };
};
export default useToaster;
