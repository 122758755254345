import { lazy, FC, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../../theme/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import { MenuTestPage } from '../pages/MenuTestPage';
import { getCSSVariableValue } from '../../theme/assets/ts/_utils';
import { WithChildren } from '../../theme/helpers';
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper';
import TeacherPageWrapper from '../pages/teacher/TeacherPageWrapper';
import StudentsPageWrapper from '../pages/students/StudentsPageWrapper';
import LessonPageWrapper from '../pages/lesson/LessonPageWrapper';
import LessonAddPage from '../pages/lesson/LessonAddPage';
import Registration from '../modules/auth/components/Registration';
import LessonEditPage from '../pages/lesson/LessonEditPage';
import LanguagePageWrapper from '../pages/language/LanguagePageWrapper';
import LiveClassPageWrapper from '../pages/liveClassContent/LiveClassContentWrapper';
import LiveClassSchedulePageWrapper from 'app/pages/liveClass/LiveClassWrapper';
import ReportedIssues from 'app/components/ReportedIssues';
import Feedbacks from 'app/components/Feedbacks';
import EmbeddedVideoLog from 'app/components/EmbeddedVideoLog';
import LessonEditMultipleLanguagePage from 'app/pages/lesson/LessonEditMultipleLanguagePage';
import UserLessonLogs from 'app/pages/userLogs/UserLessonLogsList';
import CourseWrapper from 'app/pages/course/CourseWrapper';
import WaitingClassRoom from 'app/pages/liveClass/WaitingClassRoom';
import AIActivityImagePageWrapper from 'app/pages/aiActivityImage/AIActivityImagePageWrapper';
import EnrollmentWrapper from 'app/pages/enrollment/EnrollmentWrapper';
import EnrollmentDetails from 'app/pages/enrollment/EnrollmentDetails';
import DiplomaRegistationDetails from 'app/pages/enrollment/DiplomaRegistationDetails';
import EnrollmentArchivePage from 'app/pages/enrollment/EnrollmentArchivePage';
import EnrollmentGradeSubmissionDetails from 'app/pages/enrollment/EnrollmentGradeSubmissionDetails';
import InstantLiveClassContentWrapper from 'app/pages/instantClass/InstantLiveClassContentWrapper';
import TranscriptPage from 'app/pages/enrollment/transcript/TranscriptPage';
import AddTranscript from 'app/pages/enrollment/transcript/AddTranscript';
import InstantClassLogsList from 'app/pages/userLogs/InstantClassLogsList';
import LiveClassLogsList from 'app/pages/userLogs/LiveClassLogsList';
import ViewEnrollmentDetails from 'app/pages/enrollment/viewformdetails/ViewEnrollmentDetails';
import ViewDiplomaRegistationDetails from 'app/pages/enrollment/viewformdetails/ViewDiplomaRegistrationDetails';
import ViewGradeSubmissionDetails from 'app/pages/enrollment/viewformdetails/ViewGradeSubmissionDetails';
import EnrollmentFixedIssuePage from 'app/pages/enrollment/EnrollmentFixedIssuePage';
import DiplomaDocuments from 'app/pages/enrollment/diplomaCertificate/DiplomaCertificate';
import UnEnrollmentPage from 'app/pages/enrollment/UnEnrollmentPage';
import PlatformCoursePage from 'app/pages/enrollment/PlatformCoursePage';
import ExplanationPage from 'app/pages/enrollment/ExplanationPage';
import ScheduleGoogleMeetWrapper from 'app/pages/scheduleGoogleMeet/ScheduleGoogleMeetWrapper';
import AddDiplomaCertificate from 'app/pages/enrollment/diplomaCertificate/AddDiplomaCertificate';
import UpdateDeadlineDate from 'app/pages/enrollment/transcript/UpdateDeadlineDate';

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'));
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'));
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'));
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'));
  const UsersPage = lazy(
    () => import('../modules/apps/user-management/UsersPage')
  );

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='login/*' element={<Navigate to='/dashboard' />} />

        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='teacher' element={<TeacherPageWrapper />} />
        <Route path='students' element={<StudentsPageWrapper />} />
        <Route path='lesson' element={<LessonPageWrapper />} />
        <Route path='addlesson' element={<LessonAddPage />} />
        <Route path='/registration' element={<Registration />} />
        <Route path='registration/:token' element={<Registration />} />
        <Route path='lesson/edit/:id' element={<LessonEditPage />} />
        {/* <Route path='lesson/edit' element={<LessonEditPage />} /> */}
        <Route path='languages' element={<LanguagePageWrapper />} />

        <Route
          path='lesson/edit/language/:id'
          element={<LessonEditMultipleLanguagePage />}
        />

        <Route path='reported-issues' element={<ReportedIssues />} />
        <Route path='feedbacks' element={<Feedbacks />} />

        <Route path='live-class' element={<LiveClassPageWrapper />} />
        <Route
          path='instant-live-class'
          element={<InstantLiveClassContentWrapper />}
        />
        <Route
          path='schedule-google-meet'
          element={<ScheduleGoogleMeetWrapper />}
        />
        <Route path='course' element={<CourseWrapper />} />
        <Route
          path='schedule-live-class'
          element={<LiveClassSchedulePageWrapper />}
        />
        <Route
          path='schedule-live-class/waiting-class-room/:classId'
          element={<WaitingClassRoom />}
        />

        <Route path='embedded-video-log' element={<EmbeddedVideoLog />} />
        <Route path='user-lesson-log' element={<UserLessonLogs />} />
        <Route path='instant-class-logs' element={<InstantClassLogsList />} />
        <Route path='live-class-logs' element={<LiveClassLogsList />} />
        <Route
          path='ai-activity-image'
          element={<AIActivityImagePageWrapper />}
        />
        <Route path='enrollments' element={<EnrollmentWrapper />} />
        <Route path='enrollments/active' element={<EnrollmentArchivePage />} />
        <Route
          path='enrollment-details/:enrollmentID'
          element={<EnrollmentDetails />}
        />
        <Route
          path='diploma-registration-details/:diplomaregisterID'
          element={<DiplomaRegistationDetails />}
        />
        <Route
          path='grade-submission-details/:gradesubmissionID'
          element={<EnrollmentGradeSubmissionDetails />}
        />
        <Route path='transcript' element={<TranscriptPage />} />
        <Route path='transcript/add' element={<AddTranscript />} />
        <Route path='transcript/update' element={<UpdateDeadlineDate />} />
        <Route
          path='transcript/edit/:transcriptID'
          element={<AddTranscript />}
        />

        <Route
          path='enrollment-details/view/:enrollmentID'
          element={<ViewEnrollmentDetails />}
        />
        <Route
          path='diploma-registration-details/view/:diplomaregisterID'
          element={<ViewDiplomaRegistationDetails />}
        />
        <Route
          path='grade-submission-details/view/:gradesubmissionID'
          element={<ViewGradeSubmissionDetails />}
        />

        <Route
          path='enrollments/fixed-issue'
          element={<EnrollmentFixedIssuePage />}
        />
        <Route path='diploma/certificate' element={<DiplomaDocuments />} />
        <Route
          path='diploma/certificate/add'
          element={<AddDiplomaCertificate />}
        />
        <Route
          path='diploma/certificate/edit/:certificateId'
          element={<AddDiplomaCertificate />}
        />

        <Route path='un-enrollment' element={<UnEnrollmentPage />} />
        <Route path='platform-course' element={<PlatformCoursePage />} />
        <Route path='explanation' element={<ExplanationPage />} />

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });

  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
