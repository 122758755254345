import Loader from 'app/components/Loader';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { Modal, Button, Form as BForm, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker'; // Make sure to install this package
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { usePostQuery } from 'hooks/reactQueryHelper';
import Swal from 'sweetalert2';

const EditDateModel = ({
  show,
  setOpenEditDateModal,
  selectedId,
  selectedCalenderType,
}: any) => {
  const year = moment().format('YYYY');
  const lastYear = moment().subtract(1, 'year').year();

  const updateDeadLineDate = usePostQuery(
    'updateDeadLineDate',
    '/enrollment/updateDeadlineDate'
  );

  const initialDate = {
    startDate: moment(`${lastYear}-06-01`).toDate(),
    endDate: moment(`${year}-08-01`).toDate(),
  };

  // Validation schema for Formik
  const validationSchema = Yup.object().shape({
    startDate: Yup.date().required('Start date is required').nullable(),
    endDate: Yup.date()
      .required('End date is required')
      .nullable()
      .min(Yup.ref('startDate'), 'End date must be after start date'),
  });

  // Function to handle form submission
  const handleSubmit = (values: any) => {
    const formattedStartDate = moment(values.startDate).format('YYYY-MM-DD');
    const formattedEndDate = moment(values.endDate).format('YYYY-MM-DD');

    // Combine dates into the desired string format
    const newWarningDate = `${formattedStartDate} / ${formattedEndDate}`;

    const formData = {
      newWarningDate: newWarningDate,
      CalendarYearID: selectedId,
    };
    // Here you would typically send the updated date to your backend
    updateDeadLineDate.mutate(formData);
  };

  useEffect(() => {
    if (updateDeadLineDate?.isSuccess) {
      Swal.fire({
        icon: 'success',
        title: updateDeadLineDate?.data?.ResultMessage,
        timer: 2000,
        timerProgressBar: true,
      }).then((result: any) => {
        setOpenEditDateModal(false);
      });
    }
  }, [updateDeadLineDate?.isSuccess]);

  return (
    <>
      <Modal
        size='lg'
        show={show}
        onHide={() => setOpenEditDateModal(false)}
        aria-labelledby='example-modal-sizes-title-lg'
      >
        <Modal.Header closeButton>
          <Modal.Title id='example-modal-sizes-title-lg'>
            {`Edit ${selectedCalenderType} deadline date`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='card mb-10'>
            <Loader loading={false} />{' '}
            {/* Update this with your loading logic */}
            <Formik
              initialValues={initialDate}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, handleSubmit, values }) => (
                <Form onSubmit={handleSubmit}>
                  <div className='mb-3'>
                    <BForm.Group>
                      <BForm.Label className='col-lg-4 fw-bold text-muted'>
                        Start Date
                      </BForm.Label>

                      <DatePicker
                        selected={values.startDate}
                        onChange={(date) => setFieldValue('startDate', date)}
                        dateFormat='dd/MM/yyyy'
                        className='form-control'
                        placeholderText='Select start date'
                      />
                    </BForm.Group>
                  </div>
                  <div className='mb-3'>
                    <BForm.Group>
                      <BForm.Label className='col-lg-4 fw-bold text-muted'>
                        End Date
                      </BForm.Label>
                      <DatePicker
                        selected={values.endDate}
                        onChange={(date) => setFieldValue('endDate', date)}
                        dateFormat='dd/MM/yyyy'
                        className='form-control'
                        placeholderText='Select end date'
                      />
                    </BForm.Group>
                  </div>
                  <Button type='submit' variant='primary' className='me-5'>
                    {updateDeadLineDate?.isLoading ? (
                      <Spinner
                        as='span'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Save'
                    )}
                  </Button>
                  <Button
                    type='reset'
                    variant='secondary'
                    onClick={() => setOpenEditDateModal(false)}
                  >
                    Cancel
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditDateModel;
